import * as React from "react"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import AdminSystemAdminLayout from "../../../components/admin/admin/adminSystemAdminLayout"
import { ThemeProvider } from "@material-ui/core/styles"
import darkTheme from "../../../components/ui/darkTheme"

import { makeStyles } from "@material-ui/core/styles"

import { navigate } from "gatsby"
import { UserContext } from "../../../contexts"
import { setUser } from "../../../contexts/actions"

const useStyles = makeStyles(theme => ({
  outBox: {
    width: "100%",
    minHeight: "calc(100svh  - 66px)",
    position: "relative",
  },

  inBox: {
    width: "100%",
    textAlign: "center",
    position: "absolute",
    left: "50%",
    top: "50%",
    msTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
}))
const LogOut = () => {
  const { user, dispatchUser, defaultUser } = React.useContext(UserContext)
  const classes = useStyles()

  const handleLogout = () => {
    dispatchUser(setUser(defaultUser))
    navigate("/orders/admin")
  }

  const isUserAut =
    user.jwt && user?.role?.name === "KitchenAdmin" && user.onboarding

  return (
    <ThemeProvider theme={darkTheme}>
      <AdminSystemAdminLayout
        user={user}
        isUserAut={isUserAut}
        page={"logOut"}
        footerOne={false}
        footerTwo={false}
        adminPanel={true}
      >
        <Box classes={{ root: classes.outBox }}>
          <Box classes={{ root: classes.inBox }}>
            <Typography variant="body1" paragraph>
              {"Are you sure you want to logout?"}
            </Typography>

            <Button
              variant="contained"
              color="primary"
              onClick={handleLogout}
              size="large"
              disableElevation
            >
              <Typography variant="body1">{"Logout"}</Typography>
            </Button>
          </Box>
        </Box>
      </AdminSystemAdminLayout>
    </ThemeProvider>
  )
}

export default LogOut
